import React from 'react';
import _ from 'lodash';

import components, {Layout} from '../components/index';

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
        open: true,
        scrollY: 0
      };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    let currentScroll = window.scrollY;
    let buffer = 10;

    if (this.state.open === true && currentScroll > buffer) {
      this.setState({open: false})
    } else if (this.state.open === false && currentScroll < buffer) {
      this.setState({open: true})
    }
  }
  render() {
    return (
      <Layout {...this.props} lockup={this.state.open}>
        <components.HeroBlock {...this.props}/>
        <components.EpisodeBlock {...this.props}/>
        <components.PostsBlock {...this.props}/>
        <components.SubscribeBlock {...this.props}/>
      </Layout>
    );
  }
}
